export default {
	'appointment': 'rendez-vous',
	'upcomingAppointments': 'rendez-vous à venir',
	'addAppointments': 'ajouter des rendez-vous',
	'welcomeBack': 'Bienvenue',
	'noAppointments': 'pas de rendez-vous',
	'allRightsReserverd': 'Tous les droits sont réservés',
	'privacyPolicy': 'Politique de confidentialité',
	'terms&&conditions': 'termes et conditions',
	'helpMessage': `S'il s'agit d'une urgence, appelez le 911. Si vous songez à vous faire du mal et/ou à vivre une crise, veuillez appeler la ligne de crise disponible 24 heures sur 24 au 1-800-534-4673`,
	'invoices': 'factures',
	'appointments': 'rendez-vous',
	'messages': 'messages',
	'myTasks': 'mes tâches',
	'bills': 'factures',
	'myRecords': 'mes dossiers',
	'myLibrary': 'ma bibliothèque',
	'myMembership': 'mon adhésion',
	'patientInvoices': 'factures patients',
	'noInvoices': 'pas de factures',
	'name': 'Name',
	'next': '',
	'back': '',

	//ADD-NEW AppointmentType.vue
	'appointmentType': 'Quel devis souhaitez-vous ?',
	'DAE': `Évaluation diagnostique de l'autisme`,
	'PM': 'Psychiatrie/Gestion des médicaments',
	'consent': `Je comprends que ces informations seront partagées avec Axis for Autism, LLC, j'ai le pouvoir de partager ces informations`,
	'read': `Veuillez lire et accepter le terme`,

	//FORM-HOME --> /patients/web/src/pages/intake/completingForm/Home.vue
	'who': 'Qui remplit ce formulaire ?',
	'optionSelect': 'sélectionnez votre option',
	'legal': 'juridique',
	'guardian': 'gardien',
	'adult1': `Patient adulte pour soi`,
	'adult2': `Patient adulte avec assistance`,
	'other': 'autre',
	'referal': "Comment avez-vous entendu parler d'Axis for Autism ?",
	'evaluationGoals': "Veuillez décrire pourquoi vous souhaitez que le patient soit vu à ce moment, y compris vos objectifs et vos espoirs pour l'évaluation ici",

	// form-demographic ---> Demographic
	'demographicInfo': 'Informations démographiques',
	'fullName': 'Nom complet de la personne remplissant ce formulaire',
	'patientRelation': 'Relation du patient',
	'email': 'Adresse e-mail',
	'phone': 'Téléphoner',

	// special-needs-verification SpecialNeed.vue helpMessage included

	'specialNeed': 'besoins spéciaux',
	'specialNeedMessage': `
	Ce patient a-t-il besoin d'aménagements spéciaux ? Si le patient ne parle pas anglais, ne parle pas espagnol ou a des handicaps tels que aveugle, sourd ou incapable de marcher, veuillez sélectionner « Oui » ci-dessous`,
	'consultationMessage': 'Un de nos coordonnateurs des soins aux patients vous contactera dans les 48 heures concernant la prise de rendez-vous',
	'goToHome': `Aller à la page d'accueil`,

	//form-list completingForm/List.vue

	'listMessage': `Veuillez énumérer toutes les personnes vivant dans le même domicile que le patient, y compris vous-même`,
	'firstName': 'prénom',
	'gender': 'le genre',
	'age': 'âge',
	'relation': 'rapport au malade',
	'addRelation': 'Ajouter une autre personne',
	'addMedCond16': 'Agregar otro diagnóstico o condición',

	//https://seacole.io/patients/appointment/intake/insurance-details
	'secondary_ins_verbiage': `Il est important que vous fournissiez vos informations d'assurance primaires et secondaires, même si nous sommes considérés comme hors réseau. Ne pas fournir des informations complètes pourrait entraîner le refus de vos visites.`,
};
