<template>
	<router-view :key="$router.path" />
</template>
<script>
	import lib from '!/library-web/lib';
	import utils from '@/utils';

	export default {
		name: 'App',
		data() {
			return {
				conf: null,
			};
		},
		async created() {
			// check browser
			if (!lib.browser.checkBrowser()) {
				this.$router.push(`/not_supported`);
			}

			// start pubsub
			this.conf = lib.config.get();
			utils.pubsub.init(this.conf.pubsub.host);
			await utils.pubsub.subscribe();

			// start/stop pubsub based on login events
			lib.emitter.on('login', this.onLogin);
			lib.emitter.on('logout', this.onLogout);

			// validate current token
			if (lib.store.get('token')) {
				await utils.api.post('/auth/validate');
			}
		},
		methods: {
			onLogin() {
				utils.pubsub.init(this.conf.pubsub.host);
			},
			onLogout() {
				utils.pubsub.stop();
			},
		},
		unmounted() {
			lib.emitter.off('login', this.onLogin);
			lib.emitter.off('logout', this.onLogout);
		},
		errorCaptured(err, vm, info) {
			console.error(err, vm, info);
		},
	};
</script>
<style></style>
