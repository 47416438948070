import utils from '@/utils';

class parentInitials {

	static async getParentInitialsByIntakeId(intakeId) {
        const whoIsFilling = '1';
        const fullnameQuestionId = '4';
        const adultFirstNameQuestionId = '202';
        const adultLastNameQuestionId = '204';
        const adultPatient = 'adult patient for self';
        let parentInitials;

        const response = await utils.api.get('/answers-by-question-ids?quest_ids=' + [whoIsFilling, fullnameQuestionId, adultFirstNameQuestionId, adultLastNameQuestionId] + '&intake_id=' + intakeId)

        if (response?.status) {
            const adultQuesAnswer = response.data.find(val => val.question_id === whoIsFilling);

            if(adultQuesAnswer.answer !== adultPatient) {
                const fullnameData = response.data.find(data => data.question_id === (fullnameQuestionId));
                const fullnameArr = fullnameData.answer.split(' ');
                const firstNameInitials = fullnameArr[0]?.trim()?.charAt(0) || '';
                const lastNameInitials = fullnameArr[1]?.trim()?.charAt(0) || '';

                parentInitials = `${firstNameInitials}${lastNameInitials}`;
                return parentInitials;
            }

            const firstNameData = response.data.find(data => data.question_id === adultFirstNameQuestionId);
            const lastNameData = response.data.find(data => data.question_id === adultLastNameQuestionId);

            const firstNameInitials = firstNameData?.answer?.trim()?.charAt(0) || '';
            const lastNameInitials = lastNameData?.answer?.trim()?.charAt(0) || '';

            parentInitials = `${firstNameInitials}${lastNameInitials}`;
            return parentInitials;
        }

        return '';
	}
}

export default parentInitials;
