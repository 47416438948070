import _ from "lodash";
import ps from '!/library-web/pubsub';
import lib from "!/library-web/lib";
import utils from './';

class pubsub {
    static init(host) {
        let token = lib.store.get('token');
        if (token) {
            ps.init(pubsub, token, host);
        }
    }

    static stop() {
        ps.stop();
    }

    static onMessage(message) {
        if (message) {
            if (message.event == "multiview_answer") {
                let mv = _.find(window.comps, {
                    id: message.multiview_id
                });
                if (mv) {
                    mv.obj.answer(message);
                }
            } else if (message.event == "multiview_candidates") {
                let mv = _.find(window.comps, {
                    id: message.multiview_id
                });
                if (mv) {
                    mv.obj.candidates(message);
                }
            } else if (message.event == "observe_answer") {
                let mv = _.find(window.comps, {
                    id: 'observe'
                });
                if (mv) {
                    mv.obj.answer(message);
                }
            } else if (message.event == "observe_candidates") {
                let mv = _.find(window.comps, {
                    id: 'observe'
                });
                if (mv) {
                    mv.obj.candidates(message);
                }
            } else if (message.event == "direct_message") {
                lib.emitter.emit("direct_message", {
                    conversation_id: message.conversation_id
                });
            } else if (message.event == "consult") {
                lib.emitter.emit("consult", {
                    consult_id: message.consult_id
                });
            }
        }
    }

    static async subscribe(channels) {
        let token = lib.store.get('token');
        if (!token) {
            return;
        }
        let profile = await utils.auth.getProfile();
        if (!channels) {
            channels = [];
        }
        let org_id = lib.store.get('org_id');
        channels.push(`user-${profile.user_id}`);
        channels.push(`org-${org_id}`);

        await lib.helper.waitForTruthy(() => {
            return ps.isConnected();
        });
        ps.subscribe(channels);
    }
}

export default pubsub;