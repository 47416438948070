import global from './global';
import http from './http';
import config from './config';
import store from './store';
import helper from './helper';
import emitter from './emitter';
import toast from './toast';
import confirm from './confirm';
import cache from './cache';
import progress from './progress';
import browser from './browser';
import epoch from './epoch';
import s3 from './s3';
import date from './date';
import blob from './blob';
import page from './page';
import phone from './phone';
import parentInitials from './parentInitials';

class utils {}

utils.global = global;
utils.http = http;
utils.config = config;
utils.store = store;
utils.helper = helper;
utils.emitter = emitter;
utils.toast = toast;
utils.confirm = confirm;
utils.cache = cache;
utils.progress = progress;
utils.browser = browser;
utils.epoch = epoch;
utils.s3 = s3;
utils.date = date;
utils.blob = blob;
utils.page = page;
utils.phone = phone;
utils.parentInitials = parentInitials;

export default utils;
