class config {
	static get() {
		let hostname = window.location.hostname;
		return {
			admin_api: {
				host: `${hostname}/admin/api`,
			},
			teams_api: {
				host: `${hostname}/teams/api`,
			},
			patients_api: {
				host: `${hostname}/patients/api`,
			},
			pubsub: {
				host: `${hostname}/pubsub`,
			},
			patient_web: {
				host: `${hostname}/patients`,
			},
		};
	}
}

export default config;
