import { createApp } from 'vue';
import appVue from './App.vue';
import router from './router';
import config from '../library-web/lib/config';
import helper from '../library-web/lib/helper';
import api from './utils/api';
import { createI18n } from 'vue-i18n';
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;

import VueSignature from 'vue-signature-pad';

import 'boxicons/css/boxicons.css';
import 'mosha-vue-toastify/dist/style.css';
import '@vueform/multiselect/themes/default.css';
import 'bulma/css/bulma.css';
import 'bulma-extensions/dist/css/bulma-extensions.min.css';
import 'bulma-tooltip';
import '@vueform/slider/themes/default.css';
import 'jq-timeline/dist/jquery.timeline.min.css';
import 'bulma-calendar/dist/css/bulma-calendar.min.css';
import 'nprogress/nprogress.css';
import './assets/css/app.css'; // make this last

import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import Slider from '@vueform/slider';
import English from '../src/assets/languages/english';
import Spanish from '../src/assets/languages/spanish';
import French from '../src/assets/languages/french';

import Help from '!/library-web/components/Help.vue';
import Time from '!/library-web/components/Time.vue';
import Date from '!/library-web/components/Date.vue';

import IntakeProgressBar from './components/IntakeProgressBar.vue';

api.configure(router, config.get());

// getting language from the query params
const queryParam = location.search;
const key = queryParam && queryParam.substring(1, queryParam.length).split('&').find(x => x.includes('language'));
const language = key && helper.capitalizeFirstLetter(key.split('=')[1]);

if (language) {
	localStorage.setItem('language', language);
}

const messages = {
	en: English,
	fr: French,
	er: Spanish,
};
const languageConfig = {
	English: 'en',
	Japanese: 'ja',
	French: 'fr',
	Spanish: 'er',
};
const lang = language || localStorage.getItem('language');

const i18n = createI18n({
	locale: lang ? languageConfig[lang] : 'en',
	messages,
});

let app = createApp(appVue);
app.use(i18n);
app.use(VueSignature);

app.use(router);
app.component('Form', Form);
app.component('Field', Field);
app.component('ErrorMessage', ErrorMessage);
app.component('Multiselect', Multiselect);
app.component('Slider', Slider);
app.component('Help', Help);
app.component('Time', Time);
app.component('Date', Date);
app.component('router', router);
app.component('IntakeProgressBar', IntakeProgressBar);
app.mount('#app');

window.comps = [];
