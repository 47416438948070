class blob {
    static downloadFile(blob, filename, type) {
        const url = URL.createObjectURL(new Blob([blob], {
            type: type
        }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }
}

export default blob;