import utils from './../utils';

class progressUpdater {
	static async progressUpdate(intakeId, progressValue) {
		const data = {
			intake_id: intakeId,
			progress: progressValue.toString(),
		};
		try{
			await utils.api.post('/answer-progress-update', data);
		}
		catch(e) {
			console.error('An error occurred while trying to update progress');
		}
	}
}

export default progressUpdater;
