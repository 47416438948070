import utils from '@/utils';

class page {
    static storeThis(route, skipImpersonate = null) {
        if (!skipImpersonate) {
            const intakeId = localStorage.getItem('intake_id');
            return utils.api.post(`/intake/${intakeId}/page`, {
                on_page: { 
                    name: route.name,
                    query: route.query
                }
            });
        }
    }
}

export default page;