class phone {
    /**
     * 
     * @param {*} number 
     * @returns a new number formatted with US standard (111) 222-3333.
     */
    static format(number) {
        if (!number) {
            return;
        }
        // Remove non-digit characters and leading +1 if present
        const cleanedNumber = number.replace(/\D/g, "");
        const withoutCountryCode = cleanedNumber.startsWith("1") ? cleanedNumber.substring(1) : cleanedNumber;
    
        // Match and format the number
        const x = withoutCountryCode.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    
        return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
}

export default phone;